@charset "utf-8";

/** - - - - - - - - - - - - 
 * Cake Generic 
 - - - - - - - - - - - - */

/* - - - SQL log - - - */

.cake-sql-log {
    background: #fff;
}
.cake-sql-log td {
    padding: 4px 8px;
    text-align: left;
    font-family: Monaco, Consolas, "Courier New", monospaced;
}
.cake-sql-log caption {
    color:#fff;
}

/* - - - Search List - - - */
.table th {
    border-bottom:1px solid #eee!important;
}
.table th a.asc:after {
    content: ' ⇡';
}
.table th a.desc:after {
    content: ' ⇣';
}
.paging {
    color: #ccc;
}
.paging .current,
.paging .disabled,
.paging a {
    text-decoration: none;
    padding: 5px 8px;
    display: inline-block
}
.paging > span {
    display: inline-block;
    border: 1px solid #ccc;
    border-left: 0;
}
.paging .prev {
    border-left: 1px solid #ccc;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}
.paging .next {
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}
.paging .disabled {
    color: #ddd;
}
.paging .current {
    background: #efefef;
    color: #c73e14;
}
div.input.checkbox {
    display:block;
}

/** - - - - - - - - - - - - 
 * Twitter Bootstrap Customize
 - - - - - - - - - - - - */

.nav {
    padding-right:0;
}
.navbar {
    border-radius: 0 !important;
    margin-bottom:0;
    font-size:12px;
}
.navbar-brand {
    font-family: 'Noto Sans Japanese', serif;
    font-weight:100;
    letter-spacing:.2em;
}
@media (max-width:768px) {
    .navbar {
        min-height:42px;
    }
    .navbar-brand {
        height:35px;
        padding:10px 10px;
    }
}
.navbar-text {
    margin-left:5px!important;
    margin-right:5px!important;
}
.navbar-toggle {
    padding:5px 5px;
}
.alert {
    margin:0 auto;
}
.alert.alert-dismissable .close {
    top:-2px;
}

.panel-heading {
    font-size:19px;
    font-weight:bold;
}
.error-message {
    color:#f00;
}
@media (max-width : 1024px) and (min-width:768px) {
    .table td,
    .panel .table {
        font-size:10px;
        white-space:nowrap;
    }
}
@media (max-width:768px) {
    .panel-body .btn {
        border-bottom:1px solid #ddd;
        font-size:12px;
    }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    margin-left:-5px;
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        width: 60px;
    }
    .dl-horizontal dd {
        margin-left: 80px;
    }
}

/* - - - .breadcrumb - - - */

.breadcrumb {
    padding-top:5px;
    padding-bottom:5px;
    margin-bottom:0px;
    font-size:13px;
    border-radius: 0 !important;
    border-bottom:1px solid #dfdfdf;
}
.breadcrumb a {
    color:#444;
}
.breadcrumb > li+li:before {
    content: ">"; 
}
@media (max-width : 1200px) and (min-width : 993px) {
    .breadcrumb { font-size:12px; }
    .breadcrumb > li+li:before {
        padding:0 4px;
    }
}
@media (max-width : 992px) and (min-width : 768px) {
    .breadcrumb { font-size:11px; }
    .breadcrumb > li+li:before {
        padding:0 3px;
    }
}
@media (max-width: 767px) {
    .breadcrumb { 
        padding:2px 10px;
        font-size:9px; 
    }
    .breadcrumb > li+li:before {
        padding:0 2px;
    }
}

/* - - - tab - - - */

.tab-content {
    background-color:#fff;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px;
}
@media (min-width: 767px) {
    .nav-tabs { margin-bottom: 0; }
}
.nav-tabs li a {
    background-color:#f5f5f5;
    margin-right:3px;
    border-color:#ddd #ddd #ddd;
    text-align:center;
    padding:10px;
}
.nav-tabs li.active a {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.nav-tabs li {
    min-width:70px;
}
@media (max-width : 1200px) and (min-width : 993px) {
    .nav-tabs li {
        min-width:70px;
        font-size:13px;
    }
}
@media (max-width : 992px) and (min-width : 768px) {
    .nav-tabs li {
        min-width:45px;
        font-size:11px;
    }
    .nav-tabs li a {
        padding:6px;
    }
}
@media (max-width : 767px) {
    .nav-tabs {
        margin:10px 10px 0 10px;
    }
    .tab-content {
        margin:0 10px 10px 10px;
    }
    .nav-tabs li {
        min-width:40px;
        font-size:12px;
    }
    .nav-tabs li a {
        padding:10px;
    }
}


/** - - - - - - - - - - - -
 * jQuery-UI Customize
 - - - - - - - - - - - - */

/* bootstrapのmodal(z-index=1050)より上に表示 */
.ui-datepicker {
    z-index: 1100 !important;
}

/** - - - - - - - - - - - -
 * Common Style
 - - - - - - - - - - - - */

html,body {
    height: 100%;
}
body {
    font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

ul.no-list,
ol.no-list {
    list-style-type:none;
} 
ol {
    padding-left:20px;
}
.error-message {
    color:#f00;
}

/** - - - - - - - - - - - - 
 * Modifiable by Control Panel
 - - - - - - - - - - - - */

body {
    background-color:#333;
}
.wrapperMain {
    background-color:#fff;
}

/** - - - - - - - - - - - - 
 * Layouts.ctp
 - - - - - - - - - - - - */

.sidePadding {
    padding:0 25px;
}
.main {
    margin-bottom:20px;
    min-height:600px;
}
@media (max-width: 1600px) {
    .main { width: 100%; }
}
@media (min-width: 1600px) {
    .main { width: 1600px; }
}
@media (max-width : 1200px) and (min-width : 993px) {
    .main { padding:0 20px; }
}
@media (max-width : 992px) and (min-width : 768px) {
    .main { padding:0 15px; }
}
@media (max-width: 767px) {
    .main {
        padding:0 10px;padding-bottom:5px;
        min-height:0;
    }
}

/** - - - - - - - - - - - - 
 * Elements/Lib/Common
 - - - - - - - - - - - - */

/* - - - pageHeader.ctp - - - */

.wrapperPageHeader {
    background-color:#f8f8f8;
    border-bottom:1px solid #dfdfdf;
    margin-bottom:10px;
}
.wrapperPageHeader .container {
    margin:0 auto;
}
.wrapperPageHeader .page-header {
    margin:0;
    border-bottom:none;
}
.page-header {
    margin:0 0 15px;
    padding-bottom:0;
}
.h1 {
    padding-left:0px!important;
    font-weight:bold;
    letter-spacing: .1em;
    margin:15px 0;
}
@media (min-width : 1201px) {
    .h1 { font-size:28px;}
}
@media (max-width : 1200px) and (min-width : 993px) {
    .h1 { font-size:26px; }
}
@media (max-width : 992px) and (min-width : 768px) {
    .h1 { font-size:24px; }
}
@media (max-width: 767px) {
    .wrapperPageHeader .boxBtn { margin-bottom:20px;}
    .wrapperPageHeader .container { padding:0 10px;}
    .wrapperPageHeader .h1 { font-size:16px; margin:10px 0px;}
}

/* - - - pageSubHeader.ctp - - - */

.h2 {
    padding-left:0px!important;
    letter-spacing: .1em;
    margin-top:0;
}
@media (min-width : 1201px) {
    .h2 { font-size:24px;}
}
@media (max-width : 1200px) and (min-width : 993px) {
    .h2 { font-size:22px; }
}
@media (max-width : 992px) and (min-width : 768px) {
    .h2 { font-size:20px; }
}
@media (max-width: 767px) {
    .h2 { font-size:18px; }
    .wrapperPageHeader .boxBtn { margin-bottom:20px;}
}

/** - - - - - - - - - - - - 
 * Elements/Layouts
 - - - - - - - - - - - - */

/* - - - footer.ctp - - - */

.footer {
    color: #777;
    text-align: center;
    padding-top: 30px;
}
.footer a {
    color: #fff;
}
.footerMenu .line .glyphicon {
    display:none;
}
@media (max-width: 767px) {
    .footer {
        padding-top: 10px;
    }
    .footerMenu {
        text-align: left;
        font-size:17px;
    }
    .footer .line {
        display:block;
        padding:2px 0;
    }
    .footer .line .glyphicon {
        display:inline;
    }
    .footer .sep {
        display:none;
    }
    .footer .line2 {
        display:block;
        padding:10px 0;
    }
}
.footer .copyright {
    padding:20px 0 40px 0;
}
 .textLogo {
    font-family: 'Noto Sans Japanese', serif;
    font-weight:100;
    letter-spacing:.2em;
}
/** - - - - - - - - - - - - 
 * CRUD Elements
 - - - - - - - - - - - - */

.crudList .boxSearch {
    background-color:#f8f8f8;
    border-bottom:1px solid #ddd;
}
.crudList .imageColumn {
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
}
.crudList .imageColumn img {
    max-height: 35px;
    width: auto;
}
.crudView .autoHeight {
    max-width: 100%;
    height: auto;
}

/** ================================
 * PAGES TOP
 ================================*/

@media (min-width: 1300px) {
    .jumbotron  {
        height:450px;
        padding-top:70px;
    }
}
.jumbotron  {
    background: #2fb1ff;
    /*background-image:url('/img/jumbotron.jpg');*/
    background-size: cover;
    background-position: center;
    border-radius:0px;
    background: linear-gradient(45deg, #0F151C 0%,#84BCF4 100%);
    color:#fff;
    margin-bottom:10px;
}
.jumbotron h1 {
    font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-weight:bold;
}

/** ================================
 * TOP PAGE CAROUSEL
 ================================*/

.wrapperTopCarousel .carousel .item {
    width: 100%;
    max-height: 360px;
}
.wrapperTopCarousel .carousel .item>img {
    width: 100%;
}
.wrapperTopCarousel .carousel-control {
    width:5%;
    opacity:1;
}
.wrapperTopCarousel .carousel-control:hover,
.wrapperTopCarousel .carousel-control:focus {
    opacity:0.7;
}

/** ================================
 * PAGES COMPANY
 ================================*/

@media (max-width: 767px) {
    .pagePagesDisplay .companyProfile {
        margin:0;    
    } 
}
.pagePagesDisplay .mapWrapper {
  max-width: 640px;
  min-width: 280px;
  margin: 0px auto;
  padding: 0;
}
.pagePagesDisplay .mapWrapper .googleMap {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}
.pagePagesDisplay .mapWrapper .googleMap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
.pageUsersLogin .wrapperMain {
    background-color:#444;
}

.pageUsersDashboard .panel>.table-bordered>tbody>tr:last-child>td {
    border-bottom:1px solid #ddd;
}

/** ================================
 * ROOMS INPUT
 ================================*/

.pageRoomsInput {
    .wrapperMain {
        background-color:#eee;
    }
    .main {
        margin-bottom:20px;
        min-height:800px;
        padding-bottom:20px;
    }
    @media (max-width: 1600px) {
        .main { width: 100%; height:800px; }
    }
    @media (max-width : 1200px) and (min-width : 993px) {
        .main { padding:0 20px; height:600px; }
    }
    @media (max-width : 992px) and (min-width : 768px) {
        .main { padding:0 15px; height:400px; }
    }
    @media (max-width: 767px) {
        .main {
            padding:0 10px;
            padding-bottom:5px;
            height:auto;
            .boxTermInner {
                max-height:200px!important;
            }
        }
    }
    .boxWrapper {
        height:100%;
        position:relative;
        .loading {
            width:100%;
            height:100%;
            background-color:#eee;
            text-align:center;
            padding-top:30px;
        }
        .boxTerm {
            height:100%;
            width:100%;
            position:absolute;
            background-color:#eee;
            z-index:3;
            left:0;
            top:0;
            > p {
                margin:15px;
            }
            .boxTermInner {
                background-color:#fff;
                border:1px solid #aaa;
                padding:10px;
                margin:15px;
                max-height:450px;
                overflow:scroll;
            }
            ul {
                padding-left:20px;
            }
        }
    }
    .entryControl {
        height:100%;
        padding-top:15px;
        padding-bottom:15px;
        .lblCnt {
            position:absolute;
            z-index:1;
            top:10px;
            right:10px;
            color:#555;
        }
        @media (max-width: 767px) {
            .lblCnt {
                top:2px;
            }
        }
        .lblOrder {
            color:#555;
            position:absolute;
            z-index:1;
            bottom:0;
            left:10px;
        }
    }
    .boxImg {
        position:absolute;
        height:100%;
        .wrapperEntryControl {
            height:65%;
        }
        .tblImg {
            width:100%;
            height:100%;
            img {
                border:1px solid #aaa;
                background-color:#fafafa;
                max-height:400px;
                margin:0 auto;
            }
            @media (max-width: 1600px) {
                img { max-height:380px; }
            }
            @media (max-width : 1200px) and (min-width : 993px) {
                img { max-height:350px; }
            }
            @media (max-width : 992px) and (min-width : 768px) {
                img { max-height:300px; }
            }
            @media (max-width: 767px) {
                img { max-height:50px; }
            }
            td {
                padding:5px 10px;
                text-align:center;
                vertical-align:middle;
            }
        }
        @media (max-width: 767px) {
            .tblImg {
                height:80px;
            }
        }
        .carousel, .carousel-inner {
            height:100%;
            .item {
                transition: 0.3s ease-in-out left!important;
            }
        }
    }
    .boxInput {
        height:20%;
        padding:0 10px;
        .inpDelivery { height:100%; }
        @media (max-width: 400px) { .inpDelivery { height:60px; } }
        @media (max-width: 330px) { .inpDelivery { height:40px; } }
        .inpSend, .inpSendEmpty, .inpSendUnclear {
            text-align: center;
            width:40%;
            box-shadow:inset 0 0 0 transparent;
            -webkit-appearance: none;
            border:1px solid #aaa;
            font-weight:bold;
        }
        .inpSend {
            width:40%;
            background-color:#444;
            color:#fff;
        }
        .inpSendEmpty {
            width:25%;
            font-size:10px;
            background-color:#fafafa;
            color:#444;
        }
        .inpSendUnclear {
            width:25%;
            font-size:10px;
            background-color:#fafafa;
            color:#444;
        }
    }
    .boxTabs {
        position:absolute;
        height:100%;
        .tab-content { height:90%; }
        .tab-pane { height:100%; overflow:scroll; }
    }
    .wrapperPageHeader {
        margin-bottom:0; 
    }
    .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
        position: relative;
        min-height: 1px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

/** ================================
 * PAGES GUIDE
 ================================*/

.pagesGuide {
    img {
        border:1px solid #aaa;
        width:auto;
    }
    h2 {
        margin:45px 0;
        clear:both;
    }
    h3 {
        margin:45px 0 25px 0;
        clear:both;
    }
    p {
        font-size:17px;
        margin-top:15px;
    }
    ul > li {
        font-size:17px;
    }
}

